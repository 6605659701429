import React, { useState } from 'react';
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Notification from '../../notification/Notification';
import "./PasswordResetPage.css";

async function resetPassword(options) {
    return axios.post(`/reset/password`, options)
        .then(response => response?.data);
}

export default function PasswordResetPage({notification, setNotification}) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState();
    const [confirmation, setConfirmation] = useState();
    const [submitting, setSubmitting] = useState();

    const confirmationParam = searchParams.get("confirmation");

    const handleSubmit = async e => {
        e.preventDefault();
        setNotification();

        if (password !== confirmation) {
            setNotification({severity: "error", text: "Passwords do not match."});
            return;
        }

        setSubmitting(true);

        await resetPassword({
            confirmation: confirmationParam,
            password
        }).catch(err => {
            setNotification({severity: "error", text: "Failed to send email."});
        }).finally(() => {
            setSubmitting(false);
            navigate("/home/login");
        });
    };

    return (
        <Container
            maxWidth="sm"
            className="password-reset-page">
            <Box>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign={'center'}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Reset password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="user_password"
                                label="Password"
                                type="password"
                                fullWidth
                                required
                                onChange={e => setPassword(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="user_confirm"
                                label="Confirm password"
                                type="password"
                                fullWidth
                                required
                                onChange={e => setConfirmation(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="text" type="submit" disabled={submitting} fullWidth>Submit</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Notification notification={notification}></Notification>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
}