import React from "react";
import { useState, useEffect } from "react";

import axios from "axios";

import Notification from '../notification/Notification';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';

import "./LinkDialog.css";

async function getRental(rentalId) {
  return axios.get(`/rentals/${rentalId}`)
      .then(response => response?.data);
}

async function updateCode(code) {
  return axios.put(`/link/${code.publicCode}`, code)
      .then(response => response?.data);
}

export default function LinkDialog({ open, setOpen, code, rentalId }) {

  const [name, setName] = useState('');
  const [publicCode, setPublicCode] = useState(code || '');
  const [submitting, setSubmitting] = useState();
  const [notification, setNotification] = useState();

  const handleSubmit = async e => {
    e.preventDefault();

    setSubmitting(true);
    setNotification();

    await updateCode({
        rentalId,
        publicCode
    })
    .then(() => {
      setPublicCode('');
      setOpen(false);
    })
    .catch(error => {
      console.log(error);
      setNotification({"severity": "error", "text": "Failed to link the code to the rental"});
    })
    .finally(() => {
      setSubmitting(false);
    });
};

  useEffect(() => {
    // this is the callback function that needs to be called only once (when the component has mounted)
    if (!!rentalId) {
        getRental(rentalId).then(rental => {
            setName(rental.name || '');
        });
    }
  }, [rentalId]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="link-dialog">
      <DialogTitle id="link-dialog">{name}</DialogTitle>
      <DialogContent>
        <Notification notification={notification}></Notification>
        <form onSubmit={handleSubmit}>
            <TextField
                id="public-code"
                label="Enter code"
                variant="standard"
                margin="dense"
                value={publicCode}
                autoFocus
                required
                onChange={e => setPublicCode(e.target.value)} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          fullWidth
          autoFocus
          disabled={submitting}
          onClick={e => handleSubmit(e)}>
          Link Rental
        </Button>
      </DialogActions>
    </Dialog>
  );
};