
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ConfirmDialog from "../../confirmation/ConfirmDialog";

import "./RentalList.css";

async function deleteRental(rentalId) {
    return axios.delete(`/rentals/${rentalId}`)
        .then(response => response?.data);
}

export default function RentalList({ rentals, setRentals, count, page, handleChangePage, rowsPerPage, handleChangeRowsPerPage }) {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [rentalId, setRentalId] = useState('');

    const navigate = useNavigate();

    function handleDelete(rentalId) {
        deleteRental(rentalId).then(() => {
            setRentals(rentals.filter(rental => rental.rentalId !== rentalId))
        });
    }

    function handleLink(rentalId) {
        navigate(`/welcome/rentals/${rentalId}?code=`);
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Rentals</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rentals.map(row => (
                            <TableRow
                                key={row.rentalId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Link onClick={() => navigate("/welcome/rentals/" + row.rentalId)}>{row.name}</Link>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="link" onClick={() => {
                                        handleLink(row.rentalId);
                                    }}>
                                        <LinkIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => {
                                        setConfirmOpen(true);
                                        setRentalId(row.rentalId);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmDialog
                title="Delete Rental?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => handleDelete(rentalId)}>
                Are you sure you want to delete this rental?
            </ConfirmDialog>
        </>
    );
}