
import axios from "axios";
import React, { useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ConfirmDialog from "../../confirmation/ConfirmDialog";
import SectionDialog from "../SectionDialog/SectionDialog";

import "./SectionList.css";
import { TextField } from "@mui/material";

async function createSection(rentalId, section) {
    return axios.post(`/rentals/${rentalId}/sections`, section)
        .then(response => response?.data);
}

async function updateSection(rentalId, sectionId, section) {
    return axios.put(`/rentals/${rentalId}/sections/${sectionId}`, section)
        .then(response => response?.data);
}

async function deleteSection(rentalId, sectionId) {
    return axios.delete(`/rentals/${rentalId}/sections/${sectionId}`)
        .then(response => response?.data);
}

export default function SectionList({ rentalId, sections, setSections }) {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [sectionOpen, setSectionOpen] = useState(false);
    const [sectionId, setSectionId] = useState('');
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState(-1);

    const handleClickOpen = () => {
        setSectionOpen(true);
    }

    const handleCreateSection = (section) => {
        section.order = sections.reduce((max, section) => Math.max(max, section.order), 0) + 1;

        if (section) {
            createSection(section.rentalId, section).then((section) => {
                sections.push(section);
                setSections(sections);
            }).finally(() => setSectionOpen(false));
        }
    };

    const handleUpdateSection = (section) => {
        updateSection(section.rentalId, section.sectionId, section).then(() => {
            sections[sections.findIndex(s => s.sectionId === section.sectionId)] = section;
            setSections(sections);
        }).finally(() => setSectionOpen(false));
    };

    const sortOrder = (a, b) => {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    const handleMoveUp = (section) => {
        const index = sections.findIndex(s => s.sectionId === section.sectionId);

        if (index === 0 || sections.length === 1) {
            return;
        }

        const swap = sections[index - 1]
        const order = section.order;
        section.order = swap.order;
        swap.order = order;

        updateSection(section.rentalId, section.sectionId, section);
        updateSection(swap.rentalId, swap.sectionId, swap);

        setSections([...sections.sort(sortOrder)]);
    }

    const handleMoveDown = (section) => {
        const index = sections.findIndex(s => s.sectionId === section.sectionId);

        if (index === sections.length - 1 || sections.length === 1) {
            return;
        }

        const swap = sections[index + 1]
        const order = section.order;
        section.order = swap.order;
        swap.order = order;

        updateSection(section.rentalId, section.sectionId, section);
        updateSection(swap.rentalId, swap.sectionId, swap);

        setSections([...sections.sort(sortOrder)]);
    }

    function handleDeleteSection(sectionId) {
        deleteSection(rentalId, sectionId).then(() => {
            setSections(sections.filter(section => section.sectionId !== sectionId))
        });
    }

    return (
        <div className="section-list">
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!sections && sections.map(row => (
                            <TableRow
                                key={row.sectionId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row" style={{ verticalAlign: 'top', minWidth: '10rem' }}>
                                    {row.label}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{width: '100%'}}>
                                <TextField
                                    disabled
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={row.description}/>
                                </TableCell>
                                <TableCell align="right" className="section-description" style={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                                    <IconButton aria-label="up" onClick={() => handleMoveUp(row)}>
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton aria-label="down" onClick={() => handleMoveDown(row)}>
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                    <IconButton aria-label="edit" onClick={() => {
                                        setSectionOpen(true);
                                        setSectionId(row.sectionId);
                                        setLabel(row.label);
                                        setDescription(row.description);
                                        setOrder(row.order);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => {
                                        setConfirmOpen(true);
                                        setSectionId(row.sectionId);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmDialog
                id="delete-section"
                title="Delete Section?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => handleDeleteSection(sectionId)}>
                Are you sure you want to delete this section?
            </ConfirmDialog>
            <SectionDialog
                id="new-section"
                open={sectionOpen}
                onCreate={handleCreateSection}
                onUpdate={handleUpdateSection}
                sectionId={sectionId}
                rentalId={rentalId}
                label={label}
                setLabel={setLabel}
                description={description}
                setDescription={setDescription}
                order={order}
                setOrder={setOrder}
                onClose={() => setSectionOpen(false)}>
            </SectionDialog>
            <Fab className="add" color="primary" aria-label="add" onClick={handleClickOpen}>
                <AddIcon />
            </Fab>
        </div>
    );
}