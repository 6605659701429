import axios from "axios";
import { useNavigate } from "react-router-dom";
import useToken from "../../authentication/useToken";
import "./LogoutLink.css";

export default function LogoutLink() {

    const {setToken} = useToken();
    const navigate = useNavigate();

    const logoutUser = (e) => {
        e.preventDefault();
        return axios.get(`/logout`)
            .finally(() => {
                setToken(null);
                navigate("/");
            });
    }

    return (
        <div onClick={logoutUser}>Logout</div>
    );
}