import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./ConfirmDialog.css";

export default function ConfirmDialog({ title, children, open, setOpen, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => setOpen(false)}>
          No
        </Button>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};