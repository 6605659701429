import { ChromePicker } from 'react-color';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import "./ThemeDetails.css";

export default function ThemeDetails({ backgroundColor, setBackgroundColor, fontColor, setFontColor, fontSize, setFontSize, onThemeChange }) {

    const onBackgroundColorChange = (color) => {
        backgroundColor = color.hex;
        setBackgroundColor(color.hex);
        onThemeChange({
            backgroundColor: color.hex,
            fontColor,
            fontSize: fontSize,
        });
    }

    const onFontColorChange = (color) => {
        fontColor = color.hex;
        setFontColor(color.hex);
        onThemeChange({
            backgroundColor,
            fontColor: color.hex,
            fontSize: fontSize,
        });
    }

    const onFontSizeChange = (event) => {
        fontSize = event.target.value;
        setFontSize(fontSize);
        onThemeChange({
            backgroundColor,
            fontColor,
            fontSize: fontSize,
        });
    }

    return (<>
        <div>
            <div className="color-picker-layout">
                <InputLabel className="background-color-label">Background color
                    <ChromePicker
                        className="color-picker"
                        color={backgroundColor}
                        onChangeComplete={color => onBackgroundColorChange(color)} />
                </InputLabel>
            </div>
            <div className="color-picker-layout">
                <InputLabel className="font-color-label">Font color
                    <ChromePicker
                        className="color-picker"
                        color={fontColor}
                        onChangeComplete={color => onFontColorChange(color)} />
                </InputLabel>
            </div>
            <br />
            <InputLabel className="font-size-label">Font size <br />
                <Select
                    value={fontSize}
                    label="Font size"
                    onChange={onFontSizeChange}>
                    {[8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96].map((size) => (
                        <MenuItem key={size} value={size}>{size}</MenuItem>
                    ))}
                </Select>
            </InputLabel>
            <div className="theme-preview" style={{ color: fontColor, backgroundColor: backgroundColor, fontSize: fontSize }}>Preview</div>
        </div >
    </>);
}