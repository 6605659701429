import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import axios from "axios";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';

import BrandDetails from "../BrandDetails/BrandDetails";
import ThemeDetails from "../ThemeDetails/ThemeDetails";
import SectionList from "../SectionList/SectionList";
import LinkDialog from "../../link/LinkDialog";

import "./RentalDetails.css";

async function getRental(rentalId) {
    return axios.get(`/rentals/${rentalId}`)
        .then(response => response?.data);
}

async function getSections(rentalId) {
    return axios.get(`/rentals/${rentalId}/sections?offset=0&limit=1000`)
        .then(response => response?.data);
}

async function updateRental(rentalId, rental) {
    return axios.put(`/rentals/${rentalId}`, rental)
        .then(response => response?.data);
}

export default function RentalDetails() {

    // Rental
    const { rentalId } = useParams();
    const [submitting, setSubmitting] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [logoImageId, setLogoImageId] = useState('');
    const [homeImageId, setHomeImageId] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#000');
    const [fontColor, setFontColor] = useState('#fff');
    const [fontSize, setFontSize] = useState(24);

    const [open, setOpen] = useState(false);
    const [sections, setSections] = useState([]);

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    // Tabs
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Brand
    const handleSaveImage = async (storage) => {
        await updateRental(rentalId, {
            rentalId,
            name,
            email,
            logoImageId: storage.logoImageId,
            homeImageId: storage.homeImageId,
            backgroundColor,
            fontColor
        }).finally(() => setSubmitting(false));
    }

    const handleDeleteImage = async (storage) => {
        updateRental(rentalId, {
            rentalId,
            name,
            email,
            logoImageId: storage.logoImageId,
            homeImageId: storage.homeImageId,
            backgroundColor,
            fontColor
        }).finally(() => setSubmitting(false));
    }

    // Theme
    const handleThemeChange = async (theme) => {
        updateRental(rentalId, {
            rentalId,
            name,
            email,
            logoImageId,
            homeImageId,
            backgroundColor: theme.backgroundColor,
            fontColor: theme.fontColor,
            fontSize: theme.fontSize
        }).finally(() => setSubmitting(false));
    }

    function handleLink() {
        if (searchParams.get('code') === null) {
            navigate(`/welcome/rentals/${rentalId}?code=`);
        } else {
            setOpen(true);
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitting(true);
        await updateRental(rentalId, {
            rentalId,
            name,
            email,
            logoImageId,
            homeImageId,
            backgroundColor,
            fontColor
        }).finally(() => setSubmitting(false));
    };

    useEffect(() => {
        const code = searchParams.get('code');
        if (code !== null) {
            setOpen(true);
        }
        // this is the callback function that needs to be called only once (when the component has mounted)
        if (!!rentalId) {
            getRental(rentalId).then(rental => {
                setName(rental.name || '');
                setEmail(rental.email || '');
                setLogoImageId(rental.logoImageId || '');
                setHomeImageId(rental.homeImageId || '');
                setBackgroundColor(rental.backgroundColor || '');
                setFontColor(rental.fontColor || '');
            }).then(() => {
                getSections(rentalId).then(sections => {
                    setSections(sections || []);
                });
            });
        }
    }, [rentalId, searchParams]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                className="tab-panel"
                {...other}>
                {value === index && (
                    <div>{children}</div>
                )}
            </div>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <TextField
                    id="rental-name"
                    label="Rental name"
                    variant="standard"
                    margin="dense"
                    value={name}
                    autoFocus
                    required
                    onChange={e => setName(e.target.value)} />
                <Button
                    id="rental-save-button"
                    color="primary"
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                    disabled={submitting}>Save</Button>
                <Button
                    id="rental-link-button"
                    color="primary"
                    variant="contained"
                    type="button"
                    startIcon={<LinkIcon />}
                    onClick={() => {handleLink()}}>
                    Link
                </Button>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        id="rental-tabs"
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}>
                        <Tab label="Brand" />
                        <Tab label="Theme" />
                        <Tab label="Content" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <BrandDetails
                            rentalId={rentalId}
                            logoImageId={logoImageId}
                            setLogoImageId={setLogoImageId}
                            homeImageId={homeImageId}
                            setHomeImageId={setHomeImageId}
                            onSaveImage={handleSaveImage}
                            onDeleteImage={handleDeleteImage} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ThemeDetails
                            fontColor={fontColor}
                            setFontColor={setFontColor}
                            backgroundColor={backgroundColor}
                            setBackgroundColor={setBackgroundColor}
                            fontSize={fontSize}
                            setFontSize={setFontSize}
                            onThemeChange={handleThemeChange}>
                        </ThemeDetails>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <SectionList
                            rentalId={rentalId}
                            sections={sections}
                            setSections={setSections}
                        ></SectionList>
                    </TabPanel>
                </Box>
            </form>
            <LinkDialog
                open={open}
                setOpen={setOpen}
                rentalId={rentalId}
                code={searchParams.get('code')}>
            </LinkDialog>
        </>
    );
}