import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if (!!tokenString) {
      return JSON.parse(tokenString);
    }

    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = token => {
    sessionStorage.setItem('token', JSON.stringify(!!token ? token : {}));
    setToken(token);
  };

  return {
    setToken: saveToken,
    token
  }
}