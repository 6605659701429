import { React, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import axios from "axios";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import LoginPage from "./components/authentication/LoginPage/LoginPage";
import NewAccountPage from "./components/authentication/NewAccountPage/NewAccountPage";
import ForgotUsernamePage from "./components/authentication/ForgotUsernamePage/ForgotUsernamePage";
import ForgotPasswordPage from "./components/authentication/ForgotPasswordPage/ForgotPasswordPage";
import PasswordResetPage from "./components/authentication/PasswordResetPage/PasswordResetPage";
import RentalPage from "./components/rental/RentalPage/RentalPage";
import RentalDetails from "./components/rental/RentalDetails/RentalDetails";
import HomePage from "./components/application/HomePage/HomePage";
import AdministrationPage from "./components/application/AdministrationPage/AdministrationPage";
import HtmlDisplay from "./components/html/HtmlDisplay";
import useToken from "./components/authentication/useToken";

import "./App.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// https://zenoo.github.io/mui-theme-creator/
// https://m2.material.io/inline-tools/color/
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#547960',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#92a892',
    },
  },
});


function App() {

  const { setToken } = useToken();
  const [notification, setNotification] = useState(null);

  axios.interceptors.response.use(response => response, error => {
    if (error?.response?.status === 401) {
      setToken(null);
      setNotification({ severity: "error", text: "Failed to login" });
      if (!window.location.search.includes('/home/login')) {
        window.location = '/home/login/?redirect=' + window.location.pathname + window.location.search;
      }
    } else if (error?.response?.status >= 400) {
      setNotification({ severity: "error", text: error.response.statusText });
      return Promise.reject(error);
    }
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<HomePage />}>
              <Route exact path='' element={<HtmlDisplay url="/public/learn.html"></HtmlDisplay>}></Route>
              <Route exact path='home/support' element={<HtmlDisplay url="/public/support.html"></HtmlDisplay>}></Route>
              <Route exact path='home/terms' element={<HtmlDisplay url="/public/terms.html"></HtmlDisplay>}></Route>
              <Route exact path='home/privacy' element={<HtmlDisplay url="/public/privacy.html"></HtmlDisplay>}></Route>
              <Route exact path='home/login' element={<LoginPage notification={notification} setNotification={setNotification}></LoginPage>}></Route>
              <Route exact path='home/new' element={< NewAccountPage notification={notification} setNotification={setNotification} />}></Route>
              <Route exact path='home/forgot/username' element={< ForgotUsernamePage notification={notification} setNotification={setNotification} />}></Route>
              <Route exact path='home/forgot/password' element={< ForgotPasswordPage notification={notification} setNotification={setNotification} />}></Route>
              <Route exact path='home/reset/password' element={< PasswordResetPage notification={notification} setNotification={setNotification} />}></Route>
            </Route>
            <Route exact path="/welcome" element={< AdministrationPage />}>
              <Route exact path='rentals' element={< RentalPage />}></Route>
              <Route exact path='rentals/:rentalId' element={< RentalDetails />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App;
