import { Outlet } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useParams, useNavigate } from 'react-router';
import { useState, useEffect } from "react";

import axios from "axios";

import { Breadcrumbs } from "@mui/material";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import LogoutLink from "../../authentication/LogoutLink/LogoutLink";
import useToken from "../../authentication/useToken";

import "./AdministrationPage.css";

export default function AdministrationPage() {

    const { token } = useToken();
    const navigate = useNavigate();

    async function getRental(rentalId) {
        return axios.get(`/rentals/${rentalId}`)
            .then(response => response?.data);
    }

    const [rental, setRental] = useState({});
    const params = useParams()

    useEffect(() => {
        // this is the callback function that needs to be called only once (when the component has mounted)
        getRental(params.rentalId).then(rental => {
            setRental(rental || {});
        });
    }, [params.rentalId]);

    const DynamicUserBreadcrumb = ({ match }) => {
        const rentalId = match.params.rentalId;
        if (!!rental) {
            return (<span>{rental.name}</span>);
        } else {
            return (<span>{rentalId}</span>);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const breadcrumbs = useBreadcrumbs([{
        path: '/welcome/rentals/:rentalId', breadcrumb: DynamicUserBreadcrumb
    }], {
        defaultFormatter: (string) => string.replaceAll('%20', ' '),
    });

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <HomeIcon />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: 'flex',
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        WELCOME
                    </Typography>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {!!token &&
                            <Button
                                key="Rentals"
                                onClick={() => { navigate("/welcome/rentals"); }}
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                                Rentals
                            </Button>
                        }
                        <Button
                            key="Learn"
                            onClick={() => { navigate("/"); }}
                            sx={{ my: 2, color: 'white', display: 'block' }}>
                            Learn
                        </Button>
                        <Button
                            key="Support"
                            onClick={() => { navigate("/home/support"); }}
                            sx={{ my: 2, color: 'white', display: 'block' }}>
                            Support
                        </Button>
                        <Button
                            key="Terms"
                            onClick={() => { navigate("/home/terms"); }}
                            sx={{ my: 2, color: 'white', display: 'block' }}>
                            Terms
                        </Button>
                        <Button
                            key="Privacy"
                            onClick={() => { navigate("/home/privacy"); }}
                            sx={{ my: 2, color: 'white', display: 'block' }}>
                            Privacy
                        </Button>
                    </Box>
                    <div>
                        <IconButton
                            size="large"
                            onClick={handleMenu}
                            color="inherit">
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            {!token?.accountId && <MenuItem onClick={() => {navigate("/home/login");}}>Login</MenuItem>}
                            {!!token?.accountId && <MenuItem onClick={handleClose}><LogoutLink></LogoutLink></MenuItem>}
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Container
                className="dashboard"
                maxWidth="xl">
                <Box>
                    <Breadcrumbs
                        separator="›">
                        {breadcrumbs.slice(2).map(({ match, breadcrumb }) => (
                            <Link
                                className="breadcrumb-link"
                                href={match.pathname}
                                key={match.pathname}>{breadcrumb}</Link>
                        ))}
                    </Breadcrumbs>
                    <br />
                    <Outlet />
                </Box>
            </Container>
        </Box>
    );
}