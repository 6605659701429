import React, { useState, useEffect } from 'react';

export default function HtmlDisplay({ url }) {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHTML = async () => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        setHtmlContent(text);
      } catch (error) {
        console.error('Error fetching HTML:', error);
      }
    };

    if (url) {
      fetchHTML();
    }
  }, [url]);

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
}
