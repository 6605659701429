import React, { useState } from 'react';
import axios from "axios";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import './NewRentalDialog.css';

async function createRental(rental) {
    return axios.post(`/rentals`, rental)
        .then(response => response?.data);
}

export default function NewRentalDialog({ onClose, open }) {
    const [name, setName] = useState();
    const [submitting, setSubmitting] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitting(true);
        await createRental({
            name
        }).then(rental => onClose(rental))
        .finally(() => setSubmitting(false));
    };

    const handleClose = async e => {
        e.preventDefault();
        setSubmitting(false)
        onClose(null);
    };

    return (
        <Dialog onClose={handleClose} open={open} disableRestoreFocus>
            <DialogTitle>New Rental</DialogTitle>
            <DialogContent className="new-rental-dialog">
                <form onSubmit={handleSubmit}>
                    <TextField
                        id="rental_name"
                        label="Rental name"
                        variant="standard"
                        margin="dense"
                        fullWidth
                        autoFocus
                        required
                        onChange={e => setName(e.target.value)} />
                    <Button color="primary" variant="text" type="submit" disabled={submitting} fullWidth>Save</Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}