import React, { useState, useEffect } from 'react';
import axios from "axios";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import NewRentalDialog from "../NewRentalDialog/NewRentalDialog";
import RentalList from "../RentalList/RentalList";

import "./RentalPage.css";

async function getRentals(page, rowsPerPage) {
    return axios.get(`/rentals?offset=${page * rowsPerPage}&limit=${rowsPerPage}`);
}

export default function RentalPage() {

    const [open, setOpen] = useState(false);
    const [rentals, setRentals] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        // this is the callback function that needs to be called only once (when the component has mounted)
        getRentals(page, rowsPerPage).then(response => {
            response?.headers['x-total-count'] && setCount(parseInt(response.headers['x-total-count']));
            setRentals(response?.data || []);
        });
    }, [page, rowsPerPage]);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = (rental) => {
        setOpen(false);

        if (rental) {
            rentals.push(rental);
            setRentals(rentals);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <RentalList
                rentals={rentals}
                count={count}
                page={page}
                handleChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                setRentals={setRentals}></RentalList>
            <NewRentalDialog
                open={open}
                onClose={handleClose}></NewRentalDialog>
            <Fab className="add" color="primary" aria-label="add" onClick={handleClickOpen}>
                <AddIcon />
            </Fab>
        </>
    );
}