import Alert from '@mui/material/Alert';

import "./Notification.css";

/**
 * error, warning, info, success
 * @param {*} param0
 * @returns
 */
export default function Notification({notification}) {
    if (!notification) {
        return;
    }

    return (<Alert severity={notification.severity}>{notification.text}</Alert>);
}