import React, { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import './SectionDialog.css';


export default function SectionDialog({ rentalId, sectionId, onCreate, onUpdate, onClose, open, label, setLabel, description, setDescription, order, setOrder }) {

    const [submitting, setSubmitting] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitting(true);

        const section = {
            rentalId,
            sectionId,
            label,
            description,
            order
        };

        if (!sectionId) {
            onCreate(section);
        } else {
            onUpdate(section);
        }

        setSubmitting(false);
    };

    const handleClose = async e => {
        e.preventDefault();
        setSubmitting(false);
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} disableRestoreFocus >
            <DialogTitle>Section</DialogTitle>
            <DialogContent className="new-rental-dialog">
                <form onSubmit={handleSubmit}>
                    <TextField
                        id="section-label"
                        label="Section label"
                        variant="standard"
                        margin="dense"
                        fullWidth
                        autoFocus
                        required
                        value={label}
                        onChange={e => setLabel(e.target.value)} />
                    <TextField
                        id="section-description"
                        label="Section description"
                        variant="standard"
                        margin="dense"
                        multiline
                        rows={4}
                        fullWidth
                        required
                        value={description}
                        onChange={e => setDescription(e.target.value)} />
                    <Button color="primary" variant="text" type="submit" disabled={submitting} fullWidth>Save</Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}