import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Notification from '../../notification/Notification';
import "./NewAccountPage.css";

async function createAccount(account) {
    return axios.post(`/accounts/new`, account)
        .then(response => response?.data);
}

export default function NewLoginPage({notification, setNotification}) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmation, setConfirmation] = useState();
    const [submitting, setSubmitting] = useState();

    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        setNotification();

        if (password !== confirmation) {
            setNotification({severity: "error", text: "Passwords do not match."});
            return;
        }

        setSubmitting(true);

        await createAccount({
            email,
            password,
        }).then(response => {
            setNotification({severity: "success", text: "Account created."});
            navigate("/home/login");
        }).catch(err => {
            setNotification({severity: "error", text: `Failed to create account for ${email}.`});
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Container
            maxWidth="sm"
            className="new-account-page">
            <Box>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign={'center'}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                New account
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="user_email"
                                label="Email"
                                type="email"
                                fullWidth
                                required
                                onChange={e => setEmail(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="user_password"
                                label="Password"
                                type="password"
                                fullWidth
                                required
                                onChange={e => setPassword(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="user_confirm"
                                label="Confirm password"
                                type="password"
                                fullWidth
                                required
                                onChange={e => setConfirmation(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="text" type="submit" disabled={submitting} fullWidth>Submit</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Notification notification={notification}></Notification>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
}