import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './FileChooser.css';

export function FileChooser({ id, label, onFileSelect }) {

    const handleFileChange = (event) => {
        onFileSelect(event.target.files[0]);
    };

    return (<div className='file-chooser'>
                <input
                    accept="image/*"
                    id={id}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <label htmlFor={id}>
                    <Button
                        variant="contained"
                        margin="dense"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        component="span">{label}</Button>
                </label>
            </div>);
}