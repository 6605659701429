import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Notification from '../../notification/Notification';
import "./ForgotUsernamePage.css";

async function forgotUsername(email) {
    return axios.post(`/forgot/username`, email)
        .then(response => response?.data);
}

export default function ForgotUsernamePage({notification, setNotification}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [submitting, setSubmitting] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        
        setSubmitting(true);
        setNotification();

        await forgotUsername({
            email
        }).then(() => {
            setNotification({severity: "info", text: "Email sent."});
        }).catch(err => {
            setNotification({severity: "error", text: "Failed to send email."});
        }).finally(() => {
            setSubmitting(false);
            navigate("/home/login");
        });
    };

    return (
        <Container
            maxWidth="sm"
            className="forgot-username-page">
            <Box>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign={'center'}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Forgot username
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                autoComplete="email"
                                id="forgot_email"
                                label="Email"
                                type="text"
                                fullWidth
                                required
                                onChange={e => setEmail(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="text" type="submit" disabled={submitting} fullWidth>Submit</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Notification notification={notification}></Notification>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
}