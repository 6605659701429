import React, { useState } from 'react';
import { useNavigate, Link, Outlet } from "react-router-dom";

import axios from "axios";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Notification from '../../notification/Notification';
import useToken from '../../authentication/useToken';

import './LoginPage.css';

async function loginAccount(credentials) {
    return axios.post(`/login`, credentials)
        .then(response => response?.data);
}

export default function LoginPage({ notification, setNotification }) {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [submitting, setSubmitting] = useState();

    const navigate = useNavigate();

    const {setToken} = useToken();

    const handleSubmit = async e => {
        e.preventDefault();
        setNotification();
        setSubmitting(true);
        await loginAccount({
            email: email.toLowerCase(),
            password
        }).then(response => {
            setToken(response.user);
            if (window.location.search.includes("?redirect=")) {
                // FIXME: not working
                navigate(window.location.search.replace("?redirect=", ""));
            } else {
                navigate("/welcome/rentals");  // Temporary holding for now
            }
        }).catch(err => {
            setNotification({ severity: "error", text: "Failed to login" });
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Container
            maxWidth="sm"
            className="login-page">
            <Box>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign={'center'}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Sign in
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                autoComplete="email"
                                id="login_email"
                                label="Email"
                                type="text"
                                fullWidth
                                required
                                onChange={e => setEmail(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="login_password"
                                autoComplete="current-password"
                                label="Password"
                                type="password"
                                fullWidth
                                required
                                onChange={e => setPassword(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="text" type="submit" disabled={submitting} fullWidth>SIGN IN</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/home/new">Create an account?</Link>
                            <Link to="/home/forgot/username">Forgot username?</Link>
                            <Link to="/home/forgot/password">Forgot password?</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Notification notification={notification}></Notification>
                        </Grid>
                    </Grid>
                </form>
                <Outlet />
            </Box>
        </Container>
    );
}
